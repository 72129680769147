<template>
  <div class="locale-select">
    <div class="dropdown">
      <button class="btn btn-lg dropdown-toggle" type="button"
        id="locateMenu" data-bs-toggle="dropdown" aria-expanded="false">
        {{ $root.$i18n.locale }}
      </button>
      <ul class="dropdown-menu" aria-labelledby="locateMenu">
        <li v-for="loc in Object.keys($i18n.messages)" :key="loc">
          <button
            class="dropdown-item"
            :class="loc === $root.$i18n.locale ? 'active' : ''"
            @click="changeLoc(loc)">{{ loc }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocaleSelect',
  watch: {
    '$root.$i18n.locale'(val) {
      localStorage.locale = val
      document.getElementsByTagName('html')[0].lang = val
    },
  },
  beforeMount() {
    if (localStorage.locale) {
      this.$root.$i18n.locale = localStorage.locale
      document.getElementsByTagName('html')[0].lang = this.$root.$i18n.locale
    }
  },
  methods: {
    changeLoc(loc) {
      this.$root.$i18n.locale = loc
    },
  },
}
</script>

<style lang="scss">
.locale-select{
  .dropdown {
    #locateMenu {
      background: transparent;
      color: #fff;
      border: none;
    }

    &-item {
      font-size: 1.5rem;
      padding: 0.5rem 2rem;

      &:active,
      &.active {
        color: #fff;
        text-decoration: none;
        background-color: #747678;
      }
    }

    &-menu {
      min-width: auto;
      text-align: center;
    }
  }
}

</style>
