import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/shop/:shopId',
    name: 'Shop',
    component: () => import('@/pages/ShopPage/index.vue'),
    props: true,
  },
  {
    path: '/shop/:shopId/drink-processing/:beverageId',
    name: 'Processing',
    component: () => import('@/pages/ProcessingPage/index.vue'),
    props: true,
  },
  {
    path: '*',
    redirect: { name: 'Error' },
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/pages/ErrorPage.vue'),
    props: (route) => ({
      errorCode: route.params.errorCode || 404,
    }),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
