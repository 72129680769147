import Vue from 'vue'
import Vuex from 'vuex'

import shop from './modules/shop'
import {
  SET_CURRENT_TIMESTAMP,
  SET_DOCUMENT_TITLE,
  SET_LOADER_MODAL_VALUE,
} from './mutations.type'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    timestamp: new Date(Date.UTC()),
    documentTitle: '',
    loaderModal: {
      isLoading: false,
      isError: false,
      isFatalError: false,
    },
  },
  getters: {
    timestampNow: (state) => state.timestamp,
    documentTitle: (state) => state.documentTitle,
    loaderModalValue: (state) => state.loaderModal,
  },
  mutations: {
    [SET_CURRENT_TIMESTAMP](state, timestamp) {
      state.timestamp = timestamp
    },
    [SET_DOCUMENT_TITLE](state, title) {
      state.documentTitle = title
      document.title = title
    },
    [SET_LOADER_MODAL_VALUE](state, value) {
      state.loaderModal = { ...value }
    },
  },
  actions: {},
  modules: {
    shop,
  },
  plugins: [
    (store) => {
      const timeout = () => {
        setTimeout(() => {
          store.commit(SET_CURRENT_TIMESTAMP, new Date(Date.UTC()))
          timeout()
        }, 1000)
      }
      timeout()
    },
  ],
})
