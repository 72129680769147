export default {
  ml: 'мл',
  cup: 'стакан',
  price: 'цена',
  payByCard: 'оплатить картой',
  back: 'назад',
  scanQrCoffeeMachine: 'Пожалуйста, отсканируйте QR на кофемашине!',
  coffeeMachineOffline: 'Кофемашина не в сети, попробуйте позже.',
  errorNextTry: 'Ошибка сети, мы пытаемся исправить соединение, подождите.',
  fatalError: 'Неустранимая ошибка сети, повторите попытку позже.',
  getCard: 'поднесите карту к платежному терминалу',
  paymentSuccess: 'оплата прошла успешно',
  paymentError: 'ошибка платежа, попробуйте еще раз',
  gettingCup: 'выдаем стакан, ожидайте',
  preparing: 'приготовление',
  takeYourDrink: 'готов, возьмите ваш напиток',
  beverageNotTakad: 'Возьмите ваш напиток, иначе он будет утилизирован!',
  beverageDisposal: 'Утилизация напитка',
  busy: 'Кофемашина занята, дождитесь окончания приготовления напитка.',
  noCupAvailable: 'Выбранный размер стаканов закончился, пожалуйста выберите другой напиток',
  error: 'Упс... ошибка, повторите попытку позже',
  beverageError: 'Кофемашина не готова, повторите попытку позже.',
  moneyRefund: 'Произошла ошибка, производим возврат денег на карту, приносим извинения за неудобства'
}
