import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'

import axios from 'axios'
import AspectRatio from 'v-aspect-ratio'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueLocalStorage from 'vue-localstorage'
import VueSSE from 'vue-sse'

// import withUUID from 'vue-uuid';
import App from './App.vue'
import i18n from './i18n/i18n'
import router from './router'
import AxiosService from './services/axios.service'
import store from './store'
// import VueNativeNotification from 'vue-native-notification'

AxiosService.init(router, store)

Vue.use(VueSSE)
Vue.use(VueAxios, axios)

if (process.env.VUE_APP_API_BASE_URL !== undefined) {
  Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
}

Vue.use(AspectRatio)
Vue.use(VueLocalStorage, {
  name: 'ls',
})

Vue.config.productionTip = false

// Vue.use(VueNativeNotification, {
//   requestOnNotify: true
// })

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
