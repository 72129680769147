export default {
  ml: 'მლ',
  cup: 'ჭიქა',
  price: 'ფასი',
  payByCard: 'გადახდა ბარათით',
  back: 'უკან',
  scanQrCoffeeMachine: 'გთხოვთ დაასკანიროთ QR ყავის აპარატზე!',
  coffeeMachineOffline: 'ყავის აპარატი ხაზგარეშეა, სცადეთ მოგვიანებით.',
  errorNextTry: 'ქსელის შეცდომა, ჩვენ ვცდილობთ გამოვასწოროთ კავშირი, გთხოვთ დაელოდოთ.',
  fatalError: 'ქსელის ფატალური შეცდომა, გთხოვთ, სცადოთ მოგვიანებით.',
  getCard: 'მიიტანეთ ბარათი გადახდის ტერმინალში',
  paymentSuccess: 'გადახდა წარმატებით დასრულდა',
  paymentError: 'გადახდის შეცდომა, გთხოვთ სცადოთ ხელახლა',
  gettingCup: 'ჩვენ ვაძლევთ ჭიქას, გთხოვთ დაელოდოთ',
  preparing: 'სამზარეულო',
  takeYourDrink: 'მზადაა, მიიღეთ სასმელი',
  beverageNotTakad: 'აიღეთ სასმელი, წინააღმდეგ შემთხვევაში ის განადგურდება!',
  beverageDisposal: 'სასმელის განკარგვა',
  busy: 'ყავის აპარატი დაკავებულია, გთხოვთ დაელოდოთ სანამ ყავა მზად იქნება.',
  noCupAvailable: 'შერჩეული შუშის ზომა დასრულდა, გთხოვთ, აირჩიოთ სხვა სასმელი',
  error: 'შეცდომა, სცადეთ მოგვიანებით',
  beverageError: 'ყავის აპარატი მზად არ არის, გთხოვთ, სცადოთ მოგვიანებით',
  moneyRefund: 'მოხდა შეცდომა, ჩვენ ვაბრუნებთ ბარათს, ბოდიშს გიხდით შექმნილი უხერხულობისთვის'
}
